<template>
  <span :class="css">
    <v-icon color="primary" :size="iconSize" class="mr-2">mdi-bird</v-icon>
    <span :class="textCSS">{{ stats.bird }}</span>
    <v-icon color="error" :size="iconSize" class="mr-2">mdi-finance</v-icon>
    <span :class="textCSS">{{ stats.drop }} %</span>
    <v-icon color="warning" :size="iconSize" class="mr-2">mdi-star</v-icon>
    <span :class="textCSS">{{ formatRating(stats.numReviews, stats.rating) }}</span>
  </span>
</template>

<script>
export default {
  name: 'QuickStats',
  props: {
    stats: { type: Object, required: true },
    iconSize: { type: String, default: '24' },
    spacing: { type: String, default: '5' },
    styled: { type: Boolean, default: true },
    integer: { type: Boolean, default: false },
    whiteText: { type: Boolean, default: false },
    css: { type: String, default: '' }
  },
  computed: {
    textCSS () {
      return ('text-body-1 font-weight-bold mr-' + this.spacing) + (this.whiteText ? ' white--text' : ' text--text')
    }
  },
  methods: {
    formatRating (numReviews, value) {
      if (this.integer && value % 1 === 0) {
        return value
      } else {
        return numReviews > 0 ? value.toFixed(1) : this.styled ? 'N/A' : '--.--'
      }
    }
  }
}
</script>

<style scoped>

</style>
